export const paths = {
    ROOT: '/',
    PROFILE: 'profile', 
    ITEMS: 'items',
    ITEM_DETAIL_BY_CODE: 'item_qr',
    PEOPLE: 'people',
    DASHBOARD: '/dashboard/', 
    ADMIN: '/admin/',
    ADD_PEOPLE: '/admin/people/add',
    EDIT_PEOPLE: '/admin/people/edit/:id',
    INVENTORY: 'inventory',
    ADD_INVENTORY: '/admin/inventory/add',
    EDIT_INVENTORY: '/admin/inventory/edit/:id',
    SUBSCRIPTION: 'subscription',
    ADD_ITEM: 'add_item',
    ADD_NFDI: 'add_nfdi',
    CREATE_OBJECT: 'create_object', 
    ADD_SUCCESS: '/admin/people/add_success',
    ITEM_DETAIL: 'items_details/:id',
    ITEM_SELL: 'item_sell',
    ITEM_SELL_PREPARE: 'prepare_sell_item/:id',
    LOAN_ITEM: 'loan/:id',
    AUTHENTICATE: 'authenticate/:id',
    ITEM_SUCCESS_PREPARE: 'success_prepare',
    ITEM_LAST_CHECK: 'last_check',
    ITEM_SUCCESSFULLY_SOLD: 'successfully_sold',
    ITEM_CHECK_ACCOUNT: 'check_account', 
    USER_NOT_FOUND: 'user_moderation',
    UNKNOWN_ERROR: 'unknown_error',
    MORE: 'more',
    PAYMENT: 'payment',
    DPP_EXAMPLE: 'dpp_example',
    DPP_EXAMPLE_QR_CODE: 'dpp_example_qr',
    COMMING_SOON: 'coming_soon',
    ONBOARDING: 'onboarding',
    POLICY: 'privacy_policy', 
    TERMS_OF_USE: 'terms_of_use', 
    SUCCESS_APPROVE: 'success_approve',
    INTELECTUAL_PROPERTY: 'intelectual_property',
    ARCHIVE: 'archive',
    VALIDATION_DISPLAY_REMOTELLY: 'dp_external_code',
    ITEM_EXTERNAL: 'item_external',
    VALIDATION_PARTICIPANTS: 'validation_participants', 
    VERIFIED_ITEM: 'verified_item',
    FINAL_VALIDATION: 'final_validation', 
    ITEM_SELL_BUYER: 'sell', 
    LOGIN: 'login'
}; 

export const Roles: RoleI = {
    BUYER: 'BUYER',
    DEALER:'SALES_MANAGER',
    MANAGER: 'INVENTORY_MANAGER',
    INVENTORY_SALES: 'INVENTORY_SALES_MANAGER',
    UBER_ADMIN: 'UBER_ADMIN', 
    MAIN_REPRESENTATIVE: 'MAIN_REPRESENTATIVE',
    CURATOR_REPRESENTATIVE: 'CURATOR_REPRESENTATIVE',
    IT_TECHNICAL_REPRESENTATIVE: 'IT_TECHNICAL_REPRESENTATIVE',
    DIGITAL_ARTIST: 'DIGITAL_ARTIST', 
    EXPERT: 'EXPERT', 
    BOOTSTRAPPED: 'BOOTSTRAPPED', 
};

export const readableFormatRole: any = {
    'INVENTORY_MANAGER': 'Inventory manager',
    'SALES_MANAGER': 'Sales manager',
    'BUYER': 'Buyer',
    'UBER_ADMIN': 'Uber Admin', 
    'INVENTORY_SALES_MANAGER': 'Inventory/Sales manager',
    'MAIN_REPRESENTATIVE': 'Institution Main Representative',
    'CURATOR_REPRESENTATIVE': 'Institution Curator Representative',
    'IT_TECHNICAL_REPRESENTATIVE': 'Institution IT Technical Representative',
    'DIGITAL_ARTIST': 'Digital artist', 
    'EXPERT': 'Expert', 
    'BOOTSTRAPPED': 'Bootstrapped', 
}

export const shortFormatRole: any = {
    'INVENTORY_MANAGER': 'IM',
    'SALES_MANAGER': 'SM',
    'BUYER': 'O',
    'UBER_ADMIN': 'UA', 
    'INVENTORY_SALES_MANAGER': 'ISM',
    'MAIN_REPRESENTATIVE': 'IMR',
    'CURATOR_REPRESENTATIVE': 'ICR',
    'IT_TECHNICAL_REPRESENTATIVE': 'ITR',
    'DIGITAL_ARTIST': 'DA', 
    'EXPERT': 'E', 
    'BOOTSTRAPPED': 'B', 
}

export const categories = [
    {category: 'Fashion Luxury', type: 'FASHION_LUXURY'},
    {category: 'Art work', type: 'ARTWORK'},
    {category: 'Automotive Luxury', type: 'AUTOMOTIVE_LUXURY'},
    {category: 'Real Estate Property', type: 'REAL_ESTATE_PROPERTY'},
    {category: 'Other', type: 'OTHER'}
];

export const types = [
    {name: 'Luxury Bags', type: 'LUXURY_BAGS'}, 
    {name: 'Luxury Watches', type: 'LUXURY_WATCHES'}, 
    {name: 'Luxury Clothing', type: 'LUXURY_CLOTHING'}, 
];

export const ModelCategory = {
    FASHION_LUXURY: 'Fashion Luxury',
    ARTWORK: 'Art work',
    AUTOMOTIVE_LUXURY: 'Automotive Luxury',
    REAL_ESTATE_PROPERTY: 'Real Estate Property',
    OTHER: 'Other', 
} as any;

export const ModelType = {
    LUXURY_BAGS: 'Luxury Bags',
    LUXURY_WATCHES: 'Luxury Watches',
    LUXURY_CLOTHING: 'Luxury Clothing'
} as any;

export const LonStatusEnum = {
    'CREATED': 'Loan created', 
    'APPROVED_BY_CURATOR_REPRESENTATIVE': 'Loan approved by curator',
    'APPROVED_BY_MAIN_REPRESENTATIVE': 'Loan approved by main',
    'REJECTED_BY_CURATOR_REPRESENTATIVE': 'Reject by curator',
    'REJECTED_BY_MAIN_REPRESENTATIVE': 'Reject by main'
} as any; 

export const LonStatusArtistEnum = {
    'CREATED': 'Draft created', 
    'APPROVED_BY_CURATOR_REPRESENTATIVE': 'Loan approved by curator',
    'APPROVED_BY_MAIN_REPRESENTATIVE': 'Loan approved by artist',
    'REJECTED_BY_CURATOR_REPRESENTATIVE': 'Reject by curator',
    'REJECTED_BY_MAIN_REPRESENTATIVE': 'Reject by main'
} as any; 

export const BlockchainLinkReadableType: Record<TransactionDigitalProductTypes, string> = {
    'CREATE_ITEM': 'Created',
    'LOAN_ITEM': 'Start loan',
    'CANCEL_LOAN_ITEM': 'End loan',
    'SELL_ITEM': 'Purchased',
    'APPROVE_BY_EXPERT': 'Expert opinion'
}; 

export const storageData = {
    PROFILE: 'profile_key_1', 
    DPP_EXAMPLE_KEY: 'dpp_example_key',
};

export const AppData = {
    PREV_ITEM_ID: 'prevItemId', 
};

export const CUSTOM_CLAIMS_DEVICE_KEY = 'http://item_id'; 

export const rolesList = [
    { 
        id: Roles.MANAGER, 
        name: readableFormatRole[Roles.MANAGER]
    },
    { 
        id: Roles.DEALER, 
        name: readableFormatRole[Roles.DEALER]
    },
    { 
        id: Roles.INVENTORY_SALES, 
        name: readableFormatRole[Roles.INVENTORY_SALES]
    },
    { 
        id: Roles.BUYER, 
        name: readableFormatRole[Roles.BUYER]
    },
    { 
        id: Roles.MAIN_REPRESENTATIVE, 
        name: readableFormatRole[Roles.MAIN_REPRESENTATIVE]
    },
    { 
        id: Roles.CURATOR_REPRESENTATIVE, 
        name: readableFormatRole[Roles.CURATOR_REPRESENTATIVE]
    },
    { 
        id: Roles.IT_TECHNICAL_REPRESENTATIVE, 
        name: readableFormatRole[Roles.IT_TECHNICAL_REPRESENTATIVE]
    },
    { 
        id: Roles.DIGITAL_ARTIST, 
        name: readableFormatRole[Roles.DIGITAL_ARTIST]
    },
    {
        id: Roles.EXPERT,
        name: readableFormatRole[Roles.EXPERT], 
    },
    {
        id: Roles.BOOTSTRAPPED,
        name: readableFormatRole[Roles.BOOTSTRAPPED], 
    }
  ]; 

  export const isExceptionStyleRoutes = {
    '/intelectual_property': true, 
    '/privacy_policy': true, 
    '/terms_of_use': true, 
    '/success_approve': true, 
    '/archive': true, 
    '/dpp_example': true, 
    '/item_qr': true, 
  } as any; 

  export const isSetBuyerFlowStyleRoutes = {
    [`/${paths.VALIDATION_DISPLAY_REMOTELLY}`]: true,  
    [`/${paths.ITEM_EXTERNAL}`]: true, 
  } as any; 

  export const isHideLink = {
    [`/${paths.ITEM_EXTERNAL}`]: true, 
  }

  export const typeListInventory = [
    { id: 'GENERAL', name: 'Sales functions' },
    { id: 'INSTITUTIONAL', name: 'No sales functions' }
  ];
  
  export const typeListInventoryFormatted = [
    { value: 'GENERAL', label: 'Sales functions' },
    { value: 'INSTITUTIONAL', label: 'No sales functions' }
  ];

  export const readStatusVerifyFormat = {
    'NONE': 'Required',
    'SUCCESS': 'Verified',
    'FAILED': 'Failed',
    'IN_PROGRESS': 'In progress'
  }

  export const readExpertiseStatus: any = {
    'IN_REVIEW': 'In progress',
    'FINISH_REVIEW': 'Submitted',
    'DONE': 'Finished',
  }

  export const categoryLinkToType: any = {
    'Luxury': ['Bag', 'Watch', 'Jewelry', 'Wines and Spirits'], 
    'Art': ['Painting', 'Sculpture & 3D', 'Photograph', 'Print / Work on Paper', 'Ephemeral'],
    'Artifact': ['Manuscript', 'Domestic Objects and Tools', 'Architectural'], 
    'Cars': ['Cars'],  
    'Memorabilia': ['Sport', 'Entertainment', 'Miscellaneous']
  }

  export const readSignStatus: Record<DocusignEventStatus, string> = {
      ENVELOPE_CREATED: 'Sent',
      RECIPIENT_SENT: 'Sent',
      RECIPIENT_DELIVERED: 'Sent',
      RECIPIENT_COMPLETED: 'Completed',
      RECIPIENT_DECLINED: 'Canceled',
      ENVELOPE_VOIDED: 'Canceled',
  }

  export const errorVerificationCodes: any = {
    '100.0': 'Server error, please try again later.', 
    '101.0': 'Your video could not be processed. This may be due to misalignment of your camera with the fingerprint, insufficient lighting, or improper framing during recording. Please try recording again, ensuring proper alignment with the detailed fingerprint location photos, maintaining good lighting, and minimizing any glare.', 
    '102.0': 'Your video could not be processed. This may be due to misalignment of your camera with the fingerprint, insufficient lighting, or improper framing during recording. Please try recording again, ensuring proper alignment with the detailed fingerprint location photos, maintaining good lighting, and minimizing any glare.', 
    '103.0': 'Server error, please try again later.', 
    '104.0': 'Please send message to support - nftrendsmail@gmail.com.', 
    '105.0': 'Server error, please try again later.',
    '106.0': 'Server error, please try again later.',
    '107.0': 'Server error, please try again later.',
    '108.0': 'Server error, please try again later.',
    '109.0': 'Server error, please try again later.',
    '110.0': 'Server error, please try again later.',
    '111.0': 'Your video could not be processed. This may be due to insufficient lighting or improper framing during the recording. Please try recording again, ensuring good lighting and minimizing any light glares.', 
    '112.0': 'Server error, please try again later.',
  }

  export enum ChunkTypes {
    ITEMS_VIDEO = 'ITEMS_VIDEO',
    ITEMS_IMAGE = 'ITEMS_IMAGE',
    FINGERPRINTS = 'FINGERPRINTS',
    FINGERPRINTS_PREVIEW = 'FINGERPRINTS_PREVIEW',
    INVENTORY_LICENSE = 'INVENTORY_LICENSE',
    INVENTORY_CERTIFICATE = 'INVENTORY_CERTIFICATE'
  }

