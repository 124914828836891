import { useTranslation } from 'react-i18next';
import { isExceptionStyleRoutes, isHideLink, isSetBuyerFlowStyleRoutes, paths } from '../../common/constants';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';

function Footer() {
  const { t } = useTranslation();
  const {isNoBuerFlow} = useAppContext();
/*   const isOnboarding = useMemo(() => window.location.pathname.includes(paths.ONBOARDING), [window.location.pathname]); */
/*   const isException = useMemo(() => {
    const location = window.location.pathname; 
    if(location?.includes('/intelectual_property') || location?.includes('/privacy_policy') || location?.includes('/terms_of_use') || location?.includes('/success_approve') || location?.includes('/archive') || location?.includes('/dpp_example') || location?.includes('/item_qr')) {
      return true;
    } else {
      return false; 
    }
  }, [window.location.pathname]);  */
  const isException = isExceptionStyleRoutes[window.location.pathname] ?? false;  
  const isOnboarding = window.location.pathname.includes(paths.ONBOARDING); 
  const isSetBuyerFlowStyle = isSetBuyerFlowStyleRoutes[window.location.pathname] ?? false; 
  const isHideLinks = isHideLink[window.location.pathname] ?? false; 
  const currentYear = new Date().getFullYear();
  return (
    <div className={`flex justify-between px-[40px] py-[30px] ${((isNoBuerFlow && !isOnboarding && !isSetBuyerFlowStyle) || isException ) ? 'bg-white text-black border-t border-t-[#CBCBCB]' : 'bg-blue-darkest text-white'} text-[14px] w-full `}>
        <div>
            {!isHideLinks && <nav>
                <ul className='flex space-x-4'>
                    <li className='cursor-pointer'>
                      <Link target='_blank' rel="noreferrer" to={'https://nftrends.ai/privacy-policy/'}>{t('footer.privacy_policy')}</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link target='_blank' rel="noreferrer" to={'https://nftrends.ai/intellectual-property/'}>{t('footer.terms_of_use')}</Link>
                     </li>
                     <li className='cursor-pointer'>
                       <Link target='_blank' rel="noreferrer" to={'https://nftrends.ai/terms-of-service/'}>{t('footer.intelectual_protection')}</Link>
                      </li> 
                </ul>
            </nav>}
        </div>
        <div>
          {`© ${currentYear} NFtrends`}
        </div>
        
    </div>
  )
}

export default Footer; 